import resolveImage from "./resolveImage";

const resolveResource = r => {
  const { metaImage, audiences, topics, metaDescription, postDate } = r;

  return {
    ...r,
    audiences: audiences?.map(a => a.uid),
    topics: topics?.map(t => t.uid),
    description: metaDescription,
    image: resolveImage(metaImage),
    type: r.type || "article",
    heading: r.title,
    // author:,
    // date:,
    // type:,
    descriptor: metaDescription,
    postDate: new Date(postDate).toLocaleDateString("en-US", {
      day: "2-digit",
      month: "long",
      year: "numeric",
    }),
  };
};

export default resolveResource;
