import React, { useEffect } from "react";
import { useAppState } from "@state";
import loadable from "@loadable/component";
import PageContainer from "@layout/DefaultPageContainer";
import useResourcesData from "@staticQueries/ResourcesRaQuery";

const Cta = loadable(() => import("@organisms/Cta"));
const NewsletterCta = loadable(() => import("@organisms/NewsletterCta"));
const PageBuilder = loadable(() =>
  import("../components/templates/PageBuilder")
);
const ResourceList = loadable(() => import("@organisms/ResourceList"));

const PagesDefault = ({ data, pageContext }) => {
  const { blocks, meta, hideCta, hideForm } = data;
  const { pageTheme } = pageContext;

  const resources = useResourcesData();

  const [, dispatch] = useAppState();
  useEffect(() => {
    dispatch({ type: "setThemeDark" });
  }, []);

  const topBg = "white";

  return (
    <PageContainer meta={meta}>
      <PageBuilder blocks={blocks} />
      <ResourceList items={resources} className="flex-1" />
      {!hideCta && <Cta topBg={topBg} />}
      {!hideForm && <NewsletterCta paddingBottom />}
    </PageContainer>
  );
};

PagesDefault.defaultProps = {};

export default PagesDefault;
